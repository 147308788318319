/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.preline {
    white-space:pre-line;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: green;
}

